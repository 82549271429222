const analytics = require('../analytics');
const observer = require('../lozad');
const utils = require('../utils');
const {initialiseFilterButtons} = require("./filters-ui");
require('../sorting');

let $filters = null;
let $filterPageNumbers = null;
let $openFilters = null;
let $openFiltersMobile = null;
let $pageSizeLink = null;
let $inventoryContainer = null;
let $pager = null;
let $mobileContactBar = null;
let filterType = null;
let filterTypeDisplay = null;
let makeSectionTemplate = null;
let stickyFooterHeight = null;
let url = null;
let dataUrl = null;
let filterUrl = null;
let readonly = null;
let lastScrollTop = null;

let loading = false;
let totalPageSize = 24;
let currentPage = 1;
let makes = [];
let lastCriteria = null;
let lastCriteriaHits = 0;
let mobileWidth = 812;

// Sticky filter button
let fixPosition = null;
let windowInnerHeight = null;
let buttonOffset = null;
let headerOffsetBottom = null;

if (location.href.toLowerCase().indexOf('/inventory') === -1) {
    window.localStorage.removeItem('last_scroll_top_cars');
    window.localStorage.removeItem('last_scroll_top_trucks');
}

const getLastScrollTopPosition = function () {
    return window.localStorage.getItem('last_scroll_top_' + filterType) || 0;
};

const sortNumbersAsc = (a, b) => {
    return +a - +b;
};

const buildESQueryBody = function (activeFilters) {
    let criteria = {
        query: {
            bool: {
                must: []
            }
        }
    };

    for (let field in activeFilters) {
        if (activeFilters.hasOwnProperty(field)) {
            let value = activeFilters[field];
            let query = {
                bool: {}
            };

            // Any matching query
            if (Object.prototype.toString.call(value) === '[object Array]') {
                query.bool = {
                    should: []
                };

                if (field === 'makes') {
                    value.forEach((details) => {
                        let makeQuery = {
                            bool: {
                                must: []
                            }
                        };

                        if (details.make) {
                            makeQuery.bool.must.push({match: {make: details.make}});
                        }

                        if (details.family) {
                            makeQuery.bool.must.push({wildcard: {family: details.family}});
                        }

                        if (details.variant) {
                            makeQuery.bool.must.push({wildcard: {variant: details.variant}});
                        }


                        if (makeQuery.bool.must.length > 0) {
                            query.bool.should.push(makeQuery);
                        }
                    });

                    query.bool.minimum_should_match = 1;
                } else if (field === 'locationCode') {
                    query.bool.should = value.map((item) => {
                        return {
                            [`${item.endsWith('*') ? 'prefix' : 'term'}`]: {
                                [`${field}`]: item.endsWith('*') ? item.substr(0, item.length - 1) : item
                            }
                        };
                    });
                } else if (field === 'vehicleType') {
                    value.forEach((val, i) => {
                        value[i] = val === "Pre-Owned" ? "Used" : val;
                    })
                    query.bool.should = value.map((index, item) => {
                        let fieldQuery = {};
                        fieldQuery[field] = item;
                        return {
                            match: fieldQuery
                        };
                    });
                } else if (field === 'modelIdentifier') {
                    query.bool.should = value.map((item) => {
                        let fieldQuery = {};
                        fieldQuery[field] = item;
                        return {
                            match: fieldQuery
                        };
                    });
                } else {
                    query.bool.should = value.map((index, item) => {
                        let fieldQuery = {};
                        fieldQuery[field] = item;
                        return {
                            match: fieldQuery
                        };
                    });
                }

                if (query.bool.should.length > 0) {
                    criteria.query.bool.must.push(query);
                }
            }
            // Range query
            else if (value && typeof value === 'object') {
                query.bool = {must: {range: {}}};
                query.bool.must.range[field] = {};

                if (value.min) {
                    query.bool.must.range[field].gte = value.min;
                }

                if (value.max) {
                    query.bool.must.range[field].lte = value.max;
                }

                if (Object.keys(query.bool.must.range[field]).length > 0) {
                    // Add support for handling null values when doing a max range query. Null here is
                    // inferred as the lowest value possible in the range
                    if (value.max && !value.min) {
                        criteria.query.bool.must.push({
                            bool: {
                                should: [
                                    query,
                                    {bool: {must_not: {exists: {field}}}}
                                ]
                            }
                        });
                    } else {
                        criteria.query.bool.must.push(query);
                    }
                }
            }
            // Exact match
            else if (value) {
                let fieldQuery = {};
                fieldQuery[field] = value;

                if (field === 'fullVehicleDescription') {
                    query.bool = {
                        should: value.split(',').map(function (value) {
                            return {
                                multi_match: {
                                    query: value,
                                    type: "cross_fields",
                                    fields: ["stockNumber^8", "rego^5", "vin^10", "fullVehicleDescription^3", "featuresList"],
                                    operator: "and"
                                }
                            };
                        })
                    };
                } else if (field === 'description') {
                    query.bool = {
                        must: {
                            match: {
                                fullVehicleDescription: {
                                    query: value,
                                    operator: "and"
                                }
                            }
                        }
                    };
                } else {
                    query.bool = {
                        must: {
                            match: fieldQuery
                        }
                    };
                }

                criteria.query.bool.must.push(query);
            }
        }
    }

    const selectedSortSplit = $('#inventory_sort').val().split(":");
    criteria.sort = [{}];
    criteria.sort[0][selectedSortSplit[0]] = selectedSortSplit[1];

    // Paging
    criteria.size = totalPageSize;
    criteria.from = (currentPage - 1) * totalPageSize;

    // Aggregates
    criteria.aggs = {
        make_bucket: {terms: {field: "make", "size": 1000}},
        year_bucket: {terms: {field: "vehicleYear", "size": 1000}}
    };

    if (filterType === 'cars') {
        criteria.aggs.cyls_bucket = {terms: {field: "cyls", "size": 1000}};
        criteria.aggs.seats_bucket = {terms: {field: "seats", "size": 1000}};
        criteria.aggs.doors_bucket = {terms: {field: "doors", "size": 1000}};
    }
    return criteria;
};

const clearFilters = function (withoutSave) {
    $('#location_search').val('');
    $('#modelIdentifier_search').val('');
    $('#keyword_search').val('');
    $('#condition_filter').find('.filter__list-item--active').removeClass('filter__list-item--active');
    $('#transmission_filter').find('.filter__button--active').removeClass('filter__button--active');
    $('#price_filter').find('select').val('');
    $('#odometer_filter').find('select').val('');
    $('#body_type_filter').find('.filter__list-item--active').removeClass('filter__list-item--active');
    $('#year_filter').find('select').val('');
    $('#kw_filter').find('select').val('');
    $('#cyls_filter').find('select').val('');
    $('#ancap_filter').find('svg.icon-star--active').removeClass('icon-star--active');
    $('#fuel_type_filter').find('.filter__list-item--active').removeClass('filter__list-item--active');
    $('#fuel_comp_filter').find('select').val('');
    $('#colours_filter').find('.filter__list-item--active').removeClass('filter__list-item--active');
    $('#doors_filter').find('select').val('');
    $('#seats_filter').find('select').val('');
    $('#axle_type_filter').find('.filter__list-item--active').removeClass('filter__list-item--active');
    $('#category_filter').find('.filter__list-item--active').removeClass('filter__list-item--active');
    $('#gvm_filter').find('select').val('');

    $('.filter__section--make')
        .each((index, section) => {
            let $section = $(section);
            if ($('.filter__section--make').length > 1) {
                $section.remove();
            } else {
                let $newSection = $(makeSectionTemplate);
                $section.replaceWith($newSection);
                setupMakeSection($newSection);
            }
        });

    if (!withoutSave) {
        saveFilters(true);
    }
    init();
};

const getActiveFilterValues = function () {
    let innerTextMapper = (index, elem) => {
        return elem.innerText || elem.innerHTML;
    };

    let $keywords = $('#keyword_search');
    let $location;
    let $modelIdentifier;


    if ($('#location_search').val()) {
        $location = $('#location_search').val().split(/\|/g);
    } else {
        $location = []
    }

    if ($('#modelIdentifier_search').val()) {
        $modelIdentifier = $('#modelIdentifier_search').val().split(/\|/g);
    } else {
        $modelIdentifier = []
    }

    let $condition = $('#condition_filter').find('.filter__list-item--active .filter__title');

    let values = {
        vehicleType: $condition.map(innerTextMapper),
        makes: [],
        locationCode: $location,
        modelIdentifier: $modelIdentifier
    };

    // Add makes
    $('.filter__section--make')
        .each((index, section) => {
            let $section = $(section);
            let make = $('.make', $section).val();
            let family = $('.family', $section).val();
            let variant = $('.variant', $section).val();
            if (make || family || variant) {
                values.makes.push({
                    make: make,
                    family: family,
                    variant: variant
                });
            }
        });

    if (filterType === 'cars') {
        let $transmissions = $('#transmission_filter').find('.filter__button--active .filter__title');
        let $price = $('#price_filter').find('select');
        let $odometer = $('#odometer_filter').find('select');
        let $bodyTypes = $('#body_type_filter').find('.filter__list-item--active .filter__title');
        let $year = $('#year_filter').find('select');
        let $kw = $('#kw_filter').find('select');
        let $cyls = $('#cyls_filter').find('select');
        let $ancap = $('#ancap_filter').find('svg.icon-star--active');
        let $fuelTypes = $('#fuel_type_filter').find('.filter__list-item--active .filter__title');
        let $fuelComp = $('#fuel_comp_filter').find('select');
        let $colours = $('#colours_filter').find('.filter__list-item--active .filter__title');
        let $doors = $('#doors_filter').find('select');
        let $seats = $('#seats_filter').find('select');
        values.fullVehicleDescription = ($keywords.val() || '').trim();
        values.transmission = $transmissions.map(innerTextMapper);
        values.bodyType = $bodyTypes.map(innerTextMapper);
        values.ancap = $ancap.length > 0 ? $ancap.length : null;
        values.fuelTypeFormatted = $fuelTypes.map(innerTextMapper);
        values.fuelCity = $fuelComp.val();
        values.colour = $colours.map(innerTextMapper);
        values.displayPrice = {
            min: +$price.first().val(),
            max: +$price.last().val()
        };
        values.odometer = {
            min: +$odometer.first().val(),
            max: +$odometer.last().val()
        };

        values.vehicleYear = {
            min: +$year.first().val(),
            max: +$year.last().val()
        };

        values.kw = {
            min: +$kw.first().val(),
            max: +$kw.last().val()
        };

        values.cyls = {
            min: +$cyls.first().val(),
            max: +$cyls.last().val()
        };

        values.doors = {
            min: +$doors.first().val(),
            max: +$doors.last().val()
        };

        values.seats = {
            min: +$seats.first().val(),
            max: +$seats.last().val()
        };

    } else {
        let $axleTypes = $('#axle_type_filter').find('.filter__list-item--active .filter__title');
        let $price = $('#price_filter').find('select');
        let $categories = $('#category_filter').find('.filter__list-item--active .filter__title');
        let $gvm = $('#gvm_filter').find('select');

        values.fullVehicleDescription = ($keywords.val() || '').trim();
        values.categoryOrApplication = $categories.map(innerTextMapper);
        values.axleConfiguration = $axleTypes.map(innerTextMapper);
        values.gvm = {
            min: $gvm.val()
        };
        values.displayPrice = {
            min: +$price.first().val(),
            max: +$price.last().val()
        };
    }
    return values;
};

const loadFiltersFromUrl = function () {
    let query = window.location.search.substring(1);
    query = query.replace(/[+]/g, '%20');
    let params = query.split('&').map(part => decodeURIComponent(part));
    let filters = {};

    params.forEach((expression) => {
        let m = expression.indexOf("=");
        let lhs = expression.substring(0, m);
        let rhs = expression.substring(m + 1);

        // Ignore system params that are used in iframes
        if (lhs === '__content__') {
            return;
        }

        if (rhs.indexOf(';') > -1 || lhs === 'makes') {
            filters[lhs] = rhs.split(';').filter((item) => {
                return item
            });

            if (lhs === 'makes') {
                filters[lhs] = filters[lhs]
                    .reduce((makes, expression) => {
                        let parts = expression.split(',');
                        let make = parts[0];
                        let family = parts[1];
                        let variant = parts[2];

                        if (make || family || variant) {

                            let makeObj = {};
                            if (make) {
                                makeObj.make = make;
                            }
                            if (family) {
                                makeObj.family = family;
                            }
                            if (variant) {
                                makeObj.variant = variant;
                            }

                            makes.push(makeObj);
                        }

                        return makes;
                    }, []);
            }
        } else if (rhs.indexOf(',') > -1) {
            let index = rhs.indexOf(',');
            let min = rhs.substring(0, index);
            let max = rhs.substring(index + 1);

            filters[lhs] = {};

            if (typeof max !== 'undefined') {
                filters[lhs].min = min
            }

            if (typeof max !== 'undefined') {
                filters[lhs].max = max;
            }

            if (Object.keys(filters[lhs]).length === 0) {
                delete filters[lhs];
            }
        } else {
            filters[lhs] = rhs;
        }
    });

    loadFilters(filters);
};

const loadFilters = function (filters) {
    if (!filters) {
        return;
    }

    let applyRangedValues = function (selects, value) {
        if (value) {
            if (value.min) {
                selects.first()
                    .data('currentValue', value.min)
                    .val(value.min);
            }

            if (value.max) {
                selects.last()
                    .data('currentValue', value.max)
                    .val(value.max);
            }
        }
    };

    let applyActiveStates = function (elem, values, activeClass) {
        if (values) {
            elem.find('.filter__title')
                .each((index, button) => {
                    let $button = $(button);
                    if (elem.attr('id') === "condition_filter") {
                        if (Array.isArray(values)) {
                            values.forEach(v => {
                                if ($button.text().trim() === v) {
                                    $button.parent().addClass(activeClass);
                                }
                            })
                        } else {
                            if ($button.text().trim() === values) {
                                $button.parent().addClass(activeClass);
                            }
                        }
                    } else {
                        if (values.indexOf($button.text().trim()) > -1) {
                            $button.parent().addClass(activeClass);
                        }
                    }
                });
        }
    };

    if (filters.minPrice || filters.maxPrice) {
        filters.displayPrice = {
            min: filters.minPrice,
            max: filters.maxPrice
        };
    }

    $('#keyword_search').val(filters.fullVehicleDescription || '');
    $('#location_search').val(filters.locationCode || '');
    $('#modelIdentifier_search').val(filters.modelIdentifier || '');
    applyActiveStates($('#condition_filter'), filters.vehicleType, 'filter__list-item--active');
    applyActiveStates($('#transmission_filter'), filters.transmission, 'filter__button--active');
    applyRangedValues($('#price_filter').find('select'), filters.displayPrice);
    applyRangedValues($('#odometer_filter').find('select'), filters.odometer);
    applyActiveStates($('#body_type_filter'), filters.bodyType, 'filter__list-item--active');
    applyRangedValues($('#year_filter').find('select'), filters.vehicleYear);
    applyRangedValues($('#kw_filter').find('select'), filters.kw);
    applyRangedValues($('#cyls_filter').find('select'), filters.cyls);
    applyActiveStates($('#fuel_type_filter'), filters.fuelTypeFormatted, 'filter__list-item--active');
    $('#fuel_comp_filter').find('select').val(filters.fuelCity || '');
    applyActiveStates($('#colours_filter'), filters.colour, 'filter__list-item--active');
    applyRangedValues($('#doors_filter').find('select'), filters.doors);
    applyRangedValues($('#seats_filter').find('select'), filters.seats);
    applyActiveStates($('#category_filter'), filters.categoryOrApplication, 'filter__list-item--active');
    applyActiveStates($('#filters_axle_type'), filters.axleConfiguration, 'filter__list-item--active');
    applyRangedValues($('#gvm_filter').find('select'), filters.gvm);

    if (filters.ancap) {
        $('#ancap_filter')
            .find('svg')
            .each((index, star) => {
                if (index + 1 <= filters.ancap) {
                    star.classList.add('icon-star--active');
                }
            });
    }

    // Makes
    if (filters.makes && filters.makes.length > 0) {
        let $makeSection = $('.filter__section--make');

        filters.makes
            .forEach(function (item) {
                let $newSection = $(makeSectionTemplate);
                $newSection.insertBefore($makeSection);
                setupMakeSection($newSection, item);
            });
    }

    if (filters.page) {
        currentPage = parseInt(filters.page);
    }

    if (filters.sort) {
        let sortValue = filters.sort[0] + ':' + filters.sort[1];
        let options = Array.from($('#inventory_sort')[0].options);
        let option = options.find(option => option.value === sortValue);
        if (!option) {
            $('#inventory_sort').val(options[0].value);
        } else {
            $('#inventory_sort').val(sortValue);
        }
    } else {
        if (filters.vehicleType === 'Used') {
            $('#inventory_sort').val('displayPrice:asc');
        }
    }

    // Set disabled states for the stepper buttons
    $('.inventory__filters .filter__stepper-button').forEach(function (el) {
        setStepperButtonDisabledState(el);
    });

};

const setStepperButtonDisabledState = function (el) {
    let $this = $(el);

    if ($this.attr('disabled') === 'disabled') {
        return;
    }

    let $select = $this.parent().find('select');
    let options = $select.children();
    let minValue = options[0].value;
    let maxValue = options[options.length - 1].value;
    let currentValue = $select.val();

    if (currentValue === minValue) {
        $select[0].previousElementSibling.setAttribute('disabled', 'disabled');
    } else {
        $select[0].previousElementSibling.removeAttribute('disabled');
    }

    if (currentValue === maxValue) {
        $select[0].nextElementSibling.setAttribute('disabled', 'disabled');
    } else {
        $select[0].nextElementSibling.removeAttribute('disabled');
    }
};

const saveFilters = function (addState) {
    let activeFilters = getActiveFilterValues();
    let keys = Object.keys(activeFilters);
    let query = [];
    keys.forEach((key) => {
        let value = activeFilters[key];


        let paramValue = '';
        if (value && Object.prototype.toString.call(value) === '[object Array]') {
            value = value.selector || value;
            if (key === 'makes') {
                let makeValues = [];
                value.forEach((item) => {
                    let parts = [
                        encodeURIComponent(item.make),
                        encodeURIComponent(item.family),
                        encodeURIComponent(item.variant)
                    ].filter((item) => {
                        return item && item.trim();
                    });

                    makeValues.push(parts.join(','));
                });

                paramValue += makeValues.join(';');
            } else {
                value = value
                    .filter((item) => {
                        return item && item.trim();
                    })
                    .map((item) => {
                        return encodeURIComponent(item);
                    });

                paramValue += value.join(';');
            }
        } else if (value && typeof value === 'object') {
            if (value.min && value.max) {
                paramValue += [value.min, value.max].join(',');
            } else if (value.min) {
                paramValue += value.min + ',';
            } else if (value.max) {
                paramValue += ',' + value.max;
            }
        } else if (value && typeof value === 'number') {
            paramValue += value;
        } else if (value && value.indexOf(',') > -1) {
            paramValue += value.replace(/,+/g, ';');
        } else if (value) {
            paramValue += value;
        }

        if (paramValue) {
            query.push(key + '=' + paramValue);
        }
    });

    // Only store the current page if not on the first page
    if (currentPage > 1) {
        query.push('page=' + currentPage);
    }

    // Store the sort order
    let $inventorySort = $('#inventory_sort');
    let sort = $inventorySort.val().split(":");
    if (sort.length === 2) {
        query.push('sort=' + sort[0] + ';' + sort[1]);
    }

    // Notify and parent window that the query has changed
    const filterMessage = {
        url: dataUrl + '?' + query.join('&'),
        sortedBy: $inventorySort[0].selectedIndex > -1 ? $inventorySort[0].options[$inventorySort[0].selectedIndex].label : '',
        filterCount: $inventorySort[0].selectedIndex > -1 ? query.length - 1 : query.length
    };

    // Only post messages if we are running in the query builder
    if (window.location.href.includes('/cms/components')) {
        window.parent.postMessage('__inventory_filters__' + JSON.stringify(filterMessage), '*');
    }

    if (addState) {
        if (history.pushState) {
            let newUrl = window.location.href;
            let index = window.location.href.indexOf("?");
            if (index > 0) {
                newUrl = window.location.href.substring(0, index);
            }

            if (query.length > 0) {
                query = '?' + query.join('&');
            }

            if (location.search !== query) {
                newUrl += query;
                window.history.pushState({pageSize: totalPageSize}, '', newUrl);
            }
        }
    }

    // Support live updating of page and header titles (#22938)
    $.ajax({
        type: 'POST',
        url: `inventory/${filterType}/pageTitle?${window.location.href.split('?')[1]}`,
        contentType: "application/json; charset=utf-8",
        success: function (res) {
            if (window.location.hostname.includes('/inventory/')) {
                $('#inventory-header').text(res.headerTitle);
                document.title = res.pageTitle;
            }
        },
        error: function (err) {
            console.error(err)
        }
    });
};

let filterVehicles = function (ignoreSelects, page) {
    currentPage = +page || 1;
    let activeFilters = getActiveFilterValues();
    let criteria = buildESQueryBody(activeFilters);

    updateActiveFilterCount(criteria);

    let data = {
        body: criteria
    };

    // First query ES to get all the bucket counts
    $.ajax({
        type: 'POST',
        url: filterUrl,
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        ifModified: true,
        success: function (body) {
            // Populate select options
            if (!ignoreSelects && filterType === 'cars' && body.aggregations) {
                populateRangeSelectsWithBucket('doors_filter', body.aggregations.doors_bucket.buckets, '', '', sortNumbersAsc);
                populateRangeSelectsWithBucket('seats_filter', body.aggregations.seats_bucket.buckets, '', '', sortNumbersAsc);
                populateRangeSelectsWithBucket('cyls_filter', body.aggregations.cyls_bucket.buckets, '', '', sortNumbersAsc);
            }

            // Used internally to build ES vehicle queries
            $('#query-data').val(JSON.stringify(data)).trigger('change');

            // Update the UI with the new set of counts. Formatting has to be done like this because of IE 9 and 10
            // doesn't support toLocaleString properly
            lastCriteriaHits = body.hits ? body.hits.total.value : 0;
            let hitsFormatted = utils.toNumberFormatted(lastCriteriaHits);

            $('#filter_page_count').text(hitsFormatted);

            hitsFormatted = 'VIEW ' + hitsFormatted + ' ';
            if (filterType === 'cars') {
                $('#filter_page_count_desc').text(' car' + (lastCriteriaHits === 1 ? '' : 's') + ' available');
                hitsFormatted += 'CAR' + (lastCriteriaHits === 1 ? '' : 'S');
            } else {
                $('#filter_page_count_desc').text(' truck' + (lastCriteriaHits === 1 ? '' : 's') + ' available');
                hitsFormatted += 'TRUCK' + (lastCriteriaHits === 1 ? '' : 'S');
            }

            $('#filters').find('.filter__form-button').text(hitsFormatted);

            updatePagination();
        },
        error: function () {
            lastCriteriaHits = 1;

            // Update pagination
            updatePagination();

            // Hide page count
            $('.filter__page-count').hide();
        }
    });
};

const onSearchInputChange = utils.debounce(function () {
    filterVehicles();
    if (window.innerWidth > mobileWidth) {
        renderCards(null, null, null, true);
    }
}, 300);

const getPopulateBucketValues = function (callback) {
    let activeFilters = getActiveFilterValues();
    let criteria = buildESQueryBody(activeFilters);

    let data = {
        body: criteria
    };

    // First query ES to get all the bucket counts
    $.ajax({
        type: 'POST',
        url: filterUrl,
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        ifModified: true,
        success: function (body) {
            if (body.aggregations) {
                populateRangeSelectsWithBucket('year_filter', body.aggregations.year_bucket.buckets, '', '', sortNumbersAsc);
            }

            // Criteria to retrieve all makes for a website, without the current make filter affecting it.
            let makesCriteria = buildESQueryBody({...activeFilters, makes: []});
            let makesData = {
                body: makesCriteria
            };
            $.ajax({
                type: 'POST',
                url: filterUrl,
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(makesData),
                ifModified: true,
                success: function (body) {
                    if (body.aggregations) {
                        makes = body.aggregations.make_bucket.buckets;
                        setupMakeSection($('.filter__section--make'));
                    }

                    if (callback) {
                        callback();
                    }
                },
                error: callback
            });
        },
        error: callback
    });
};

const getActiveFilterCount = function (criteria) {
    if (criteria.query) {
        return criteria.query.bool.must.reduce(function (sum, next) {
            if (next.bool.should) {
                sum += next.bool.should.filter(predicate => !predicate.bool?.must_not).length;
            } else {
                sum += 1;
            }

            return sum;
        }, 0);
    }

    return 0;
};

const updateActiveFilterCount = function (criteria) {
    // Update active filter count
    let $filters = $('#filters');
    let $clearFilter = $filters.find('.filter__actions-right .filter__link');
    let $refineButtons = $('.active-filter-count');
    let $label = $filters.find('.filter__actions-left');
    let activeFilterCount = getActiveFilterCount(criteria);

    if (activeFilterCount) {
        $refineButtons.text('FILTER (' + activeFilterCount + ')');
        $label.text(activeFilterCount + ' filter' + (activeFilterCount === 1 ? '' : 's') + ' active');
        $clearFilter.removeAttr('disabled');
    } else {
        $refineButtons.text('FILTER');
        $label.text('No Filters Applied');
        $clearFilter.attr('disabled', '');
    }
};

const updatePagination = function (readFromInput, reset) {
    let totalPages = Math.ceil(lastCriteriaHits / totalPageSize) || 1;
    let $buttons = $pager.find('.filter__stepper-button');
    let $suffix = $pager.find('.suffix');
    let $first = $buttons.first();
    let $last = $buttons.last();
    let $input = $pager.find('input');

    if (readFromInput) {
        currentPage = +$input.val() || 0;
    }
    if (reset) {
        currentPage = 1;
    }

    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    if (currentPage === 1) {
        $first.attr('disabled', 'disabled');
    } else {
        $first.removeAttr('disabled');
    }

    if (currentPage === totalPages) {
        $last.attr('disabled', 'disabled');
    } else {
        $last.removeAttr('disabled');
    }

    $input.data('totalPages', totalPages);
    $input.val(currentPage);
    $suffix.text('of ' + totalPages);
};

const renderCards = function (callback, noScrollTop, forceUpdate, addState) {
    let activeFilters = getActiveFilterValues();
    let criteria = buildESQueryBody(activeFilters);

    if (utils.deepEqual(lastCriteria, activeFilters) && !forceUpdate) {
        document.body.scrollTop = lastScrollTop;
        return;
    }

    if (!noScrollTop && !$('#open_filters_desktop').data('open')) {
        scrollBodyToTheTop();
    }

    // Active loading state
    lastCriteria = activeFilters;
    $inventoryContainer.addClass('loading');

    let data = {
        body: criteria
    };

    $.ajax({
        type: 'POST',
        url: dataUrl + (readonly === true ? '?readonly=' + readonly : ''),
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        ifModified: true,
        success: function (cards) {
            // Handle when the full page has been returned and ignore it.
            if (cards.includes('<html')) {
                $inventoryContainer.removeClass('loading');
                return;
            }

            $('#inventory_cards').replaceWith(cards);

            // re-initialise the lazy loading observer
            observer.observe();

            updateViewLinkDisplay();

            setTimeout(function () {
                calc();
                stick();

                if (callback) {
                    callback();
                }
                // Define elements which will trigger analytics events
                const googleAnalyticsEventCatch = $('[analytics-event-catch]');
                googleAnalyticsEventCatch.on('click', function () {
                    analytics.trackEventByElementAttributes($(this));
                });
            }, 0);

            $inventoryContainer.removeClass('loading');

            if (addState) {
                saveFilters(true);
            } else {
                saveFilters();
            }
        },
        error: function () {
            console.error(arguments);
            $inventoryContainer.removeClass('loading');

            // Notify and parent window that the query has changed
            // Only post messages if we are running in the query builder
            if (window.location.href.includes('/cms/components')) {
                window.parent.postMessage('__inventory_filters__', '*');
            }
        }
    });
};

const updateViewLinkDisplay = function () {
    if (!utils.isLargeScreen()) {
        if (lastCriteriaHits === 0) {
            $pageSizeLink.hide();
        } else {
            if (lastCriteriaHits > 48) {
                $filterPageNumbers.show();
            } else {
                $filterPageNumbers.hide();
            }
        }
    } else {
        $pageSizeLink.removeAttr('style');
    }

};

const populateRangeSelectsWithBucket = function (filterId, buckets, prefix, suffix, sortFn, emptyPlaceholder) {
    let $selects = $('#' + filterId).find('select');
    let $minSelect = $selects.first();
    let $maxSelect = $selects.last();

    let minValue = $minSelect.val();
    let maxSelect = $maxSelect.val();

    // Clear options because we are going to rebuild them.
    $selects.html('');

    if (!emptyPlaceholder) {
        $minSelect.append('<option value="">' + prefix + 'No Minimum' + suffix + '</option>');
        $maxSelect.append('<option value="">' + prefix + 'No Maximum' + suffix + '</option>');
    } else {
        $selects.append('<option value=""></option>');
    }

    buckets = buckets.map(function (bucket) {
        return bucket.key;
    }).reverse();

    if (sortFn) {
        buckets = buckets.sort(sortFn)
    } else {
        buckets.alphanumSort(true);
    }

    buckets.forEach((name) => {
        if (name) {
            $selects.append('<option value="' + name + '">' + name + '</option>');
        }
    });

    $minSelect.val(minValue);
    $maxSelect.val(maxSelect);
};

const populateRangeSelects = function (filterId, minValue, maxValue, interval, labelFormatter) {
    let $selects = $('#' + filterId).find('select');

    $selects.each((index, select) => {
        let $select = $(select);

        // Handle reinitialising selects by clearing what was generated
        $select.empty();
        $select.append(`<option value="">No ${index === 0 ? 'Minimum' : 'Maximum'}</option>`)

        let $lastOption = null;
        for (let i = minValue; i <= maxValue; i += interval) {
            let label = labelFormatter(i);
            if (label !== undefined && label !== null) {
                $lastOption = $('<option value="' + i + '">' + label + '</option>');
                $select.append($lastOption);

                // Handle when the current value of the range doesn't exist as a step. Add the val as a step in the
                // right order
                const val = $select.data('currentValue');
                if (val !== undefined && val !== null && (val - (val % interval) - i) === 0) {
                    if (val !== i) {
                        $select.append('<option value="' + val + '">' + labelFormatter(val) + '</option>');
                    }
                } else if (val < minValue && i === minValue) {
                    $lastOption.before('<option value="' + val + '">' + labelFormatter(val) + '</option>');
                }
            }
        }
    });
};

const populateVehicleSelect = function ($select, buckets, placeholder, currentValue) {
    currentValue = currentValue || $select.val();
    $select.html('');
    $select.append('<option value="">' + placeholder + '</option>');

    let list = buckets.map((bucket) => {
        return bucket.key;
    });

    let matched = list.filter((item) => {
        return item === currentValue
    })[0];

    if (!matched && currentValue) {
        list.push(currentValue);
    }

    list?.alphanumSort(true);

    list?.forEach((item) => {
        $select.append('<option value="' + item + '">' + item + '</option>');
    });

    $select.val(currentValue);
};

const updateMakeReadonlyState = function ($section) {
    let $addAnotherLink = $section.children().last();
    let $label = $section.find('.filter__label');
    let $details = $section.find('.filter__details');
    let $save = $section.find('.filter__close');
    let $clear = $details.find('.icon');
    let $text = $details.find('.filter__title');
    let $make = $section.find('.make');
    let $family = $section.find('.family');
    let $variant = $section.find('.variant');

    $text.text(['All', $make.val(), $family.val(), $variant.val()].join(' ').trim());
    $label.text(filterTypeDisplay);

    $details.show();
    $addAnotherLink.hide();
    $variant.parent().hide();
    $family.parent().hide();
    $make.parent().hide();
    $save.hide();

    $text
        .off('click')
        .on('click', function () {
            if ($variant.val()) {
                $variant.parent().show();
            }

            if ($family.val()) {
                $variant.parent().show();
                $family.parent().show();
            }

            if ($make.val()) {
                $family.parent().show();
                $make.parent().show();
            }

            $details.hide();
            $save.show();
            $label.text('Select Car');

            if ($section.data('initOnOpen')) {

                $make.trigger('change', {excludeFilter: true});

                if ($family.val()) {
                    $family.trigger('change', {excludeFilter: true});
                }

                $section.data('initOnOpen', null);
            }

            $section.addClass('open');
        });

    $save
        .off('click')
        .on('click', function () {
            if (!$make.val()) {
                $clear.trigger('click');
            } else {
                updateMakeReadonlyState($section);
                $section.removeClass('open');
            }
        });

    $clear
        .off('click')
        .on('click', function () {
            if ($('.filter__section--make').length > 1) {
                $section.remove();
            } else {
                let $newSection = $(makeSectionTemplate);
                $section.replaceWith($newSection);
                setupMakeSection($newSection);
            }

            filterVehicles();
            renderCards(null, null, true, true);
        });
};

const getRelatedVehicleOptions = function ($section, $changedSelect, callback) {
    let $makeSelect = $section.find('.make');
    let $familySelect = $section.find('.family');

    let data = {
        body: {
            size: 0,
            query: {bool: {must: []}},
            aggs: {}
        }
    };

    if ($changedSelect.hasClass('make')) {
        data.body.query.bool.must.push({match: {make: $makeSelect.val()}});
        data.body.aggs.family_bucket = {terms: {field: "family", "size": 1000}};
    }

    if ($changedSelect.hasClass('family')) {
        data.body.query.bool.must.push({match: {family: $familySelect.val()}});
        data.body.aggs.variant_bucket = {terms: {field: "variant", "size": 1000}};
    }

    $.ajax({
        type: 'POST',
        url: filterUrl,
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        ifModified: true,
        success: callback
    });
};

const setupMakeSection = function ($section, selectedVehicle) {
    let $addAnotherLink = $section.children().last();
    let $makeSelect = $section.find('.make');
    let $familySelect = $section.find('.family');
    let $variantSelect = $section.find('.variant');

    if (selectedVehicle) {
        if (selectedVehicle.make) {
            populateVehicleSelect($makeSelect, makes, 'Any Make', selectedVehicle.make);
            $makeSelect.val(selectedVehicle.make);
        }

        if (selectedVehicle.family) {
            populateVehicleSelect($familySelect, [{key: selectedVehicle.family}], 'Any Model');
            $familySelect.val(selectedVehicle.family);
        }

        if (selectedVehicle.variant) {
            populateVehicleSelect($variantSelect, [{key: selectedVehicle.variant}], 'Any Variant');
            $variantSelect.val(selectedVehicle.variant);
        }

        $section.data('initOnOpen', true);

        // Make this section readonly
        updateMakeReadonlyState($section);
    } else {
        populateVehicleSelect($makeSelect, makes, 'Any Make');
    }

    $section
        .find('.make, .family, .variant')
        .off('change')
        .on('change', function (e) {
            let $select = $(this);
            let isSectionInitialising = $section.data('initOnOpen');

            if ($select.hasClass('make') && !isSectionInitialising) {
                $familySelect.val('');
                populateVehicleSelect($familySelect, [], 'Any Model');

                $variantSelect.parent().hide();

                if (!$select.val()) {
                    $familySelect.parent().hide();
                    filterVehicles();
                    if (window.innerWidth > mobileWidth) {
                        renderCards(null, null, null, true);
                    }
                    return;
                }
            }

            if (($select.hasClass('make') || $select.hasClass('family')) && !isSectionInitialising) {
                $variantSelect.val('');
                populateVehicleSelect($variantSelect, [], 'Any Variant');
            }

            getRelatedVehicleOptions($section, $select, (body) => {
                // Make select changed
                if ($select.hasClass('make')) {

                    // Setup the family dropdown
                    populateVehicleSelect($familySelect, body.aggregations.family_bucket.buckets, 'Any Model');
                    if ($select.val()) {
                        $familySelect.parent().show();
                    }

                    // Now because a make has been selected show the 'Add' link
                    if (!$section.hasClass('open')) {
                        $addAnotherLink.show();
                    }
                } else if ($select.hasClass('family')) {
                    // Setup the variant dropdown
                    populateVehicleSelect($variantSelect, body.aggregations.variant_bucket.buckets, 'Any Variant');
                    if ($select.val()) {
                        $variantSelect.parent().show();
                    }
                }
            });

            if (!e._args || e._args.excludeFilter !== true) {
                filterVehicles();
                if (window.innerWidth > mobileWidth) {
                    renderCards(null, null, null, true);
                }
            }
        });

    $addAnotherLink
        .off('click')
        .on('click', function () {
            // Make the current section read only
            updateMakeReadonlyState($section);

            // Append a new make section
            let $newSection = $(makeSectionTemplate);
            $section.after($newSection);

            setupMakeSection($newSection);
        });
};

const scrollBodyToTheTop = function () {
    // Scroll to top of page
    const nav = document.querySelector('.nav');
    const top = document.getElementById('inventory_container').offsetTop;
    if (nav) {
        window.scroll(0, top - (nav.offsetTop + nav.offsetHeight));
    } else {
        window.scroll(0, top);
    }
    calc();
    stick();
};

const saveScrollPosition = function () {
    window.localStorage.setItem('last_scroll_top_' + filterType, document.body.scrollTop);
};

const bindClickEvents = function () {
    let $filters = $('#filters');
    let $clearFilter = $filters.find('.filter__actions-right .filter__link');

    $('#keyword_search')
        .off('input')
        .on('input', function () {
            onSearchInputChange();
        });

    $('#inventory_sort')
        .off('change')
        .on('change', function () {
            updatePagination(null, true);
            renderCards(null, true, true, true);
        });

    $('#filters .filter__section:not(.filter__section--make) select, #filters_body_type select, #filters_design select, #filters_technical select, #filters_gvm select')
        .off('change')
        .on('change', function () {
            filterVehicles(true);

            let closestStepper = $(this).closest('.filter__stepper');
            if (closestStepper[0]) {
                setStepperButtonDisabledState(closestStepper);
            }

            if (window.innerWidth > mobileWidth) {
                renderCards(null, null, true, true);
            }
        });

    $filters
        .find('.filter__form-button')
        .on('click', function () {
            renderCards(null, null, null, true);
        });

    $clearFilter
        .off('click')
        .on('click', function () {
            clearFilters();
            filterVehicles();
            if (window.innerWidth > mobileWidth) {
                renderCards(null, null, true, true);
            }
        });

    $('#ancap_filter')
        .find('.filter__link')
        .on('click', function () {
            $('#ancap_filter').find('.icon-star').removeClass('icon-star--active');
            filterVehicles();
            if (window.innerWidth > mobileWidth) {
                renderCards(null, null, true, true);
            }
        });

    $('.filter__button, .filter__list-item, #ancap_filter .icon-star')
        .on('click', function () {
            filterVehicles();
            if (window.innerWidth > mobileWidth) {
                renderCards(null, null, true, true);
            }
        });

    $('.inventory__filters .filter__stepper-button')
        .off('click')
        .on('click', function () {

            let $this = $(this);

            if ($this.attr('disabled') === 'disabled') {
                return;
            }

            let $select = $this.parent().find('select');
            let options = $select.children();

            let currentValue = $select.val();
            let offset = 0;

            let direction = $this.data('direction');
            if (direction === 'backward') {
                offset--;
            } else if (direction === 'forward') {
                offset++;
            }
            let currentIndex = 0;

            options.forEach((option, index) => {
                if (option.value === currentValue) {
                    currentIndex = index;
                }
            });

            currentValue = options[currentIndex + offset].value;

            $select.val(currentValue);
            setStepperButtonDisabledState($this);

            filterVehicles();
            renderCards(null, null, true, true);
        });

    $('.filter__stepper-button', $pager)
        .off('click')
        .on('click', function () {
            let $this = $(this);

            if ($this.attr('disabled') === 'disabled') {
                return;
            }

            let direction = $this.data('direction');
            if (direction === 'backward') {
                currentPage--;
            } else if (direction === 'forward') {
                currentPage++;
            }

            updatePagination();
            saveFilters(true);
            scrollBodyToTheTop();
            renderCards(null, false, true, true);
        });

    $('input', $pager)
        .off('change')
        .on('change', function () {
            if (!this.validity.valid) {
                return;
            }

            updatePagination(true);
            renderCards(null, false, true, true);
        });

    $pageSizeLink
        .off('click')
        .on('click', function () {
            $pageSizeLink.text('View ' + totalPageSize + ' per page');
            totalPageSize = totalPageSize === 24 ? 48 : 24;

            // Track the page size change event
            analytics.trackGoogleEvent('inventory', 'change total page size', totalPageSize);

            updatePagination();

            renderCards(null, true, true, true);
        });

    $openFilters
        .on('click', function () {
            lastCriteria = getActiveFilterValues();
        });

    // Rebuild the makes section but resetting the filter state
    clearFilters(true);
    loadFiltersFromUrl();
};

const bindWindowDocumentEvents = function () {
    $(document)
        .on('scroll', function () {
            window.requestAnimationFrame(() => {
                let currentScrollTop = document.body.scrollTop;
                let didStick = stick();

                if (didStick || currentScrollTop <= 0) {
                    return $openFiltersMobile.css('opacity', 1);
                }

                if (currentScrollTop === lastScrollTop) {
                    return;
                }

                // If scrolling down then
                if (lastScrollTop < currentScrollTop && currentScrollTop > headerOffsetBottom) {
                    $openFiltersMobile.css('opacity', 0);
                } else {
                    $openFiltersMobile.css('opacity', 1);
                }

                lastScrollTop = currentScrollTop;

                saveScrollPosition();
            });
        });

    $(window)
        .on('resize', function () {
            window.requestAnimationFrame(() => {
                lastScrollTop = document.body.scrollTop;
                saveScrollPosition();

                calc();
                stick();
                updateViewLinkDisplay();
            });
        })
        .on('orientationchange', function () {
            window.requestAnimationFrame(() => {
                lastScrollTop = document.body.scrollTop;
                saveScrollPosition();

                calc();
                stick();

                // Use two frames to refresh the layout of the body. This forces flex to recalculate
                let $content = $('body > .content');
                window.requestAnimationFrame(() => {
                    $content.removeClass('content');

                    window.requestAnimationFrame(() => {
                        $content.addClass('content')
                    });
                });

                updateViewLinkDisplay();
            });
        });
};

let calc = function () {
    headerOffsetBottom = $inventoryContainer[0].offsetTop
    windowInnerHeight = window.innerHeight;
    buttonOffset = $openFiltersMobile[0].offsetHeight + 40;
    fixPosition = $pager[0].offsetTop + $pager[0].offsetHeight + 32;
};

let stick = function () {
    if (utils.isMediumScreen()) {
        $openFiltersMobile.show();
    } else {
        $openFiltersMobile.hide();
        return;
    }

    let scrollTop = document.body.scrollTop;
    let scrollBottom = Math.floor(scrollTop + windowInnerHeight - stickyFooterHeight);

    if (scrollBottom >= fixPosition + buttonOffset) {
        $openFiltersMobile
            .removeClass('inventory__button-fixed')
            .addClass('inventory__button-stick')
            .css({
                top: fixPosition + 'px' // height inc
            });
        return true;
    } else {
        $openFiltersMobile
            .removeClass('inventory__button-stick')
            .addClass('inventory__button-fixed')
            .css({
                top: 'auto'
            });

        return false;
    }
};

const init = function (popstate) {
    if (loading) {
        return;
    }

    if (popstate || window.performance.navigation.type === 1 || window.performance.navigation.type === 2) {
        let queryString = window.location.search;

        if (typeof (queryString) === 'undefined' || queryString === '') {
            currentPage = 1;
        } else {
            let queryComponents = queryString.replace('?', '').split('&');
            let pageChanged = false;

            queryComponents.forEach(function (item) {
                if (item.indexOf('page') > -1) {
                    currentPage = parseInt(item.replace('page=', ''));
                    pageChanged = true;
                }
            });
            currentPage = pageChanged ? currentPage : 1;
        }
        totalPageSize = window.history.state && window.history.state.pageSize || 24;

        let pageSizeLabel = totalPageSize === 24 ? '48' : '24';
        $pageSizeLink.text('View ' + pageSizeLabel + ' per page');
    }

    // Fixes an issue with the sort field not should text navigating back to the inventory page
    if (filterType === 'cars') {
        $('#inventory_sort').val('daysInStock:asc');
    } else {
        $('#inventory_sort').val('lastModifiedDate:desc');
    }

    loading = true;

    $(document.body)
        .off('filters_rebuilt')
        .on('filters_rebuilt', bindClickEvents);

    if (!popstate) {

        loadFiltersFromUrl();

        populateRangeSelects('price_filter', 5000, 150000, 5000, (value) => {
            return '$' + utils.toNumberFormatted(value);
        });

        if (filterType === 'cars') {
            populateRangeSelects('odometer_filter', 20000, 200000, 20000, (value) => {
                return utils.toNumberFormatted(value);
            });

            populateRangeSelects('doors_filter', 2, 5, 1, (value) => {
                return utils.toNumberFormatted(value);
            });

            populateRangeSelects('seats_filter', 2, 8, 1, (value) => {
                return utils.toNumberFormatted(value);
            });

            populateRangeSelects('cyls_filter', 3, 8, 1, (value) => {
                return value !== 7 ? utils.toNumberFormatted(value) : null;
            });
        }

        getPopulateBucketValues(() => {
            initialiseFilterButtons()
            filterVehicles(null, currentPage);
            renderCards(() => {
                document.body.scrollTop = lastScrollTop;
                loading = false;

                setTimeout(function () {
                    calc();
                    stick();

                    $openFiltersMobile.css('opacity', 1);

                    bindWindowDocumentEvents();
                }, 300);
            }, true, true);
        });
    } else {
        initialiseFilterButtons()
        filterVehicles(null, currentPage);
        renderCards(() => {
            document.body.scrollTop = lastScrollTop;
            loading = false;

            setTimeout(function () {
                calc();
                stick();

                $openFiltersMobile.css('opacity', 1);

                bindWindowDocumentEvents();
            }, 300);
        }, true, true)
    }
};

module.exports = {
    init: (dontWaitForOnLoadEvent) => {
        $filters = $('#filters');
        $filterPageNumbers = $('#filter_page_number_of_cars');
        $openFilters = $('#open_filters, #open_filters_desktop');
        $openFiltersMobile = $('#open_filters');
        $pageSizeLink = $('.inventory__header-left .link');
        $inventoryContainer = $('#inventory_container');
        $pager = $('#pagination');
        $mobileContactBar = $('.mobile-contact-bar');
        filterType = $filters.data('filterType') === 'trucks' ? 'trucks' : 'cars';
        filterTypeDisplay = filterType[0].toUpperCase() + filterType.substring(1, filterType.length - 1);
        makeSectionTemplate = require('!!raw-loader!./vehicle-filter.html').default.replace(/\{\{filterType\}\}/g, filterTypeDisplay);
        stickyFooterHeight = ($mobileContactBar.length > 0 ? $mobileContactBar[0].offsetHeight : 0);
        url = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname; // If the url ends with a slash, remove it.
        dataUrl = $inventoryContainer.data('dataUrl') || url;
        filterUrl = $inventoryContainer.data('filterUrl') || url + '/filter';
        readonly = !!$inventoryContainer.data('readonly');
        lastScrollTop = getLastScrollTopPosition();

        if ($filters.length > 0) {
            // Handle when the page loads, or changes state using back and forward buttons
            $(window)
                .off('load pageshow')
                .on('load pageshow', () => {
                    init();
                });

            $(window)
                .off('popstate')
                .on('popstate', () => {
                    loading = false;
                    init(true);
                });

            $(window)
                .off('beforeunload')
                .on('beforeunload', () => {
                    saveFilters();
                    saveScrollPosition();
                });
        }

        if (dontWaitForOnLoadEvent) {
            init();
        }
    }
}

$(document).ready(() => module.exports.init());